import { createClientApp, type AppClientModule } from '@donkeyjs/client';
import {
  clientModuleProducts,
  clientModuleShopping,
  parseProductOrderItem,
  setShoppingSettings,
} from '@donkeyjs/module-shopping';
import { settings } from '../settings';
import { blockPresets } from './blocks';
import { Main } from './main';
import { theme } from './theme';
import { views } from './views';

setShoppingSettings({
  stripe: {
    publicKey:
      'pk_test_51B3KxOKzfujL285S1Fg5psAgWACY8oSlYzw01MR9QwnqDZR3n3wdRdpPEEM4Ms93mnbaWQPqx3TCnfc5kN2NHcpw00HCJ7e30e',
  },
  parseOrderItem: (item) => parseProductOrderItem(item)!,
});

export const app = createClientApp({
  ...settings,
  blockPresets,
  root: Main,
  backOffice: {
    load: () => import('@donkeyjs/backoffice').then((b) => b.BackOffice),
  },
  modules: [
    clientModuleShopping,
    clientModuleProducts as AppClientModule<DataSchema>,
  ],
  theme,
  views,
});
