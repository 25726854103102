import { baseSchema } from '@donkeyjs/core';
import { schemaEnGB } from '@donkeyjs/i18n-en-gb';
import { schemaNlNL } from '@donkeyjs/i18n-nl-nl';
import { shoppingSchemaExtension } from '@donkeyjs/module-shopping';
import { createSchema, extendSchema } from '@donkeyjs/proxy';

const { result } = extendSchema(baseSchema)
  .with({ i18n: { 'en-GB': schemaEnGB, nl: schemaNlNL } })
  .with(shoppingSchemaExtension)
  .with({
    resolvers: {
      Deployment: {
        deployments: {
          return: 'many',
          sort: [{ name: 'DESC', order: ['at:desc'] }],
        },
      },

      TraceEvent: {
        exceptions: {
          return: 'many',
          where: { type: ['type', ['eq']], source: ['source', ['eq']] },
          sort: [{ name: 'LAST_SEEN', order: ['at:desc'] }],
        },
      },
    },
  } as const);

export const schema = createSchema(result, {
  cultures: ['en-GB', 'nl'],
});

export type AppSchema = typeof schema;
