import { createTheme } from '@donkeyjs/client';

export const theme = () =>
  createTheme({
    colors: {
      background: '#3e3e3e',
      color: '#fff',
      error: '#b00020',
      accent: '#80deea',
      secondary: '#8b700f',
      'text-on-error': '#ffffff',
      'text-on-accent': '#ffffff',
    },

    font: '11pt/1.5em "Raleway"',

    webFonts: { google: { families: ['Raleway'] } },
  });
