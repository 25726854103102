import { Blocks } from '@donkeyjs/client';
import { updateComponent } from '@donkeyjs/jsx-runtime';
import styles from './main.module.css';

export function Main() {
  return (
    <div class={styles.main}>
      <Blocks />
    </div>
  );
}

if (import.meta.hot) {
  import.meta.hot.accept((hot) => {
    updateComponent(Main, hot?.Main);
  });
}
