import {
  createView,
  getI18n,
  traceColor,
  traceDescription,
  traceIcon,
  traceName,
} from '@donkeyjs/client';
import { setState } from '@donkeyjs/jsx-runtime';
import { PhPlusSquare } from '@donkeyjs/phosphor-icons';
import { formatRelative } from 'date-fns';
import listStyles from './DashboardList.module.css';
import styles from './ViewException.module.css';

export const ViewException = createView<DataSchema, 'TraceEvent'>(
  {
    name: () => 'Exception',
    groupings: [
      {
        key: 'message',
        format: (node) => node.exception?.message || 'Unknown Exception',
        name: () => 'Message',
        renderContainer: (group, _, children) => (
          <Group name={group.name} at={group.items[0].at}>
            {children}
          </Group>
        ),
      },
    ],
  },

  function ViewException(props) {
    const i18n = getI18n();
    const state = setState({ expanded: false });

    return (
      <div class={styles.event}>
        <button
          class={styles.header}
          type="button"
          onclick={() => (state.expanded = !state.expanded)}
        >
          <span class={styles.expander}>
            <PhPlusSquare />
          </span>
          <span class={styles.at}>
            {() =>
              formatRelative(props.node.at, new Date(), {
                locale: i18n.dateLocale,
              })
            }
          </span>
          <div class={styles.tags}>
            <span>{props.node.appKey}</span>
            <span>{props.node.source}</span>
            <span>{props.node.trace?.userEmail}</span>
          </div>
        </button>
        {() =>
          state.expanded && (
            <div class={styles.trace}>
              {props.node.trace?.events.$((event) => (
                <div
                  class={styles.traceEvent}
                  style={`--event-color: ${traceColor(event) || 'inherit'};`}
                >
                  <div class={styles.traceEventIcon}>
                    {traceIcon(event)({ weight: 'light' })}
                  </div>
                  <div class={styles.traceDetails}>
                    {traceName(event)}
                    {traceDescription(event)}
                  </div>
                </div>
              ))}
            </div>
          )
        }
      </div>
    );
  },
);

function Group(props: { name: string; at: Date; children?: JSX.Children }) {
  const i18n = getI18n();
  return (
    <div class={[listStyles.list, styles.list]}>
      <div class={styles.lastOccurrence}>
        {() =>
          formatRelative(props.at, new Date(), { locale: i18n.dateLocale })
        }
      </div>
      <div class={styles.name}>{props.name}</div>
      {props.children}
    </div>
  );
}
