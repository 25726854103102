import type { BlockPreset } from '@donkeyjs/client';
import { PhBug, PhCloud } from '@donkeyjs/phosphor-icons';

export const blocks = {
  product: {},
};

export const blockPresets: BlockPreset[] = [
  {
    name: () => 'Deployments',
    icon: () => <PhCloud weight="duotone" />,
    values: {
      type: 'data',
      settings: { resolver: 'deployments', view: 'default' },
    },
  },

  {
    name: () => 'Exceptions',
    icon: () => <PhBug weight="duotone" />,
    values: {
      type: 'data',
      settings: { resolver: 'exceptions', view: 'default' },
    },
  },
];
