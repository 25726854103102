import {
  lazyComponent,
  type BlockPreset,
  type BlocksType,
} from '@donkeyjs/client';
import { PhShoppingCart } from '@donkeyjs/phosphor-icons';
import { PaymentForm } from '../payments/PaymentForm';

export const blocks: BlocksType = {
  'shopping-cart': {
    component: lazyComponent('shopping/cart', () =>
      import('./cart/CartBlock').then((m) => m.CartBlock),
    ),
  },
  payment: { component: PaymentForm },
};

export const blockPresets: BlockPreset[] = [
  {
    name: () => 'Cart',
    icon: () => <PhShoppingCart weight="duotone" />,
    group: () => 'Widgets',
    values: {
      type: 'shopping-cart',
    },
  },
];
