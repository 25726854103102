import { createView, getI18n } from '@donkeyjs/client';
import { formatRelative } from 'date-fns';
import listStyles from './DashboardList.module.css';
import styles from './ViewDeployment.module.css';

export const ViewDeployment = createView<DataSchema, 'Deployment'>(
  {
    name: () => 'Deployment',
    groupings: [
      {
        key: 'appKey',
        format: (node) => node.appKey,
        name: () => 'App',
        renderContainer: (group, _, children) => (
          <Group name={group.name}>{children}</Group>
        ),
      },
    ],
  },

  function ViewDeployment(props) {
    const i18n = getI18n();
    return (
      <div>
        {() =>
          formatRelative(props.node.at, new Date(), { locale: i18n.dateLocale })
        }
      </div>
    );
  },
);

function Group(props: { name: string; children?: JSX.Children }) {
  return (
    <div class={[styles.group, listStyles.list]}>
      <div class={styles.appKey}>{props.name}</div>
      <div class={styles.groupDeployments}>{props.children}</div>
    </div>
  );
}
