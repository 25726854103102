import { bind } from '@donkeyjs/jsx-runtime';
import styles from './Code.module.css';

interface CodeProps {
  children?: JSX.Children;
  block?: boolean;
}

export function Code(props: CodeProps) {
  return (
    <code
      class={bind(() => [styles.code, { [styles.displayBlock]: props.block }])}
    >
      {props.children}
    </code>
  );
}
